import React from 'react';
import ImageGrid from './ImageGrid';
import About from './About';
import Contact from './Contact';
import './App.css'; // Import your CSS file

const App = () => {
  return (
    <div className="App">
      <header className="header">
        <h1 className="title">Louise Schliemann Interior Design</h1>
        
      </header>
      <ImageGrid />
      <Contact />
    </div>
  );
};

export default App;
