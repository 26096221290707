import React from 'react';
import './ImageGrid.css'; // Import the CSS for styling

const images = [
  '/images/IMG_0784.jpeg', 
  '/images/IMG_0785.jpeg', 
  '/images/IMG_0786.jpeg', 
  '/images/IMG_0787.jpeg',  
  '/images/IMG_0790.jpeg', 
  '/images/IMG_0791.jpeg', 
  '/images/IMG_0792.jpeg', 
  '/images/IMG_0793.jpeg', 
  '/images/IMG_0795.jpeg', 
  '/images/IMG_0796.jpeg', 
  '/images/IMG_0797.jpeg', 
  '/images/IMG_0798.jpeg',
  '/images/IMG_0799.jpeg',
  '/images/IMG_0800.jpeg',
  '/images/IMG_0801.jpeg',
  '/images/IMG_0802.jpeg',

  
  
];

const ImageGrid = () => {
  return (
    <div className="image-grid">
      {images.map((image, index) => (
        <div key={index} className="image-item">
          <img src={image} alt={`Interior design ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
